import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Tiny’s & The Bar Upstairs",
    pageDescription:
      "A downtown NYC restaurant and bar spends less time and money on inventory management.",
    title: "Controlling costs with confidence",
    subTitle: `How Tiny’s & The Bar Upstairs saves time and ensures quality with SpotOn’s MarginEdge integration.`,
    businessName: "Tiny’s & The Bar Upstairs",
    industry: "Restaurant, Casual dining",
    location: "New York City, New York",
    products: [spotonProducts.RESTAURANT, spotonProducts.ORDER, spotonProducts.MARGIN_EDGE,],
    description:
      "The chefs and managers at Tiny’s & The Bar Upstairs don’t have time to shuffle through receipts. There are dishes to plate, guests to greet, and oysters to shuck. With SpotOn’s MarginEdge integration, they can scan invoices as they arrive using a mobile device instead of manually entering product and delivery information. MarginEdge’s recipe-building feature helps maintain quality and consistency, giving the Tiny’s team a true cost, attached instructions, and a helpful photo to go with each menu item. And since MarginEdge integrates directly with their SpotOn Restaurant POS, Tiny’s can ensure they have the most accurate numbers for calculating costs.",
    quoteData: {
      imgName: "tinys-and-the-bar-upstairs.png",
      quote: `“I think the customer service and support team at SpotOn is phenomenal. I’ve been in the industry for some 30 years and have worked with every POS system you can think of. SpotOn’s support team is key. The person that sold me the POS still texts me and supports me. It’s fantastic.”`,
      personName: "Rachid Abdelouahad",
      personTitle: "Director of Operations, Neighborhood Projects",
    },
    goal: `Tiny’s & The Bar Upstairs needed a fluid and functional inventory management system for chefs and managers on the ground to simplify taking inventory, logging, and invoices. Abdelouahad needed a system that worked seamlessly with their restaurant POS, to get an accurate calculation of cost.`,
    solution: `MarginEdge and SpotOn’s integrations mean chefs and managers can easily manage inventory and Director of Operations Abdelouahad has the clearest possible picture of cost of goods. When it comes time to train a new chef or negotiate with purveyors, Abdelouahad has all the information he needs to ensure the highest quality while controlling costs. Combined, Tiny’s & The Bar Upstairs is saving 17+ hours per week, and as everyone in the restaurant industry knows—time is money.`,
    results: {
      title: `The results`,
      stats: [
        { title: `2 hours`, description: `saved per day for chefs` },
        {
          title: `2 hours`,
          description: `saved per week for managers`,
        },
        {
          title: `5 hours`,
          description: `saved on monthly inventory`,
        },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "Financing chef-driven concepts",
        imageName: "bardo-and-vana.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-07-07",
        href: "/case-studies/bardo-and-vana",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Creating better work experiences with SpotOn Teamwork",
        imageName: "the-bungalow-kitchen.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-08-18",
        href: "/case-studies/the-bungalow-kitchen",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
        ],
    },
    {
        title: "Cooking up real Southern food with quick, seamless ordering",
        imageName: "wisteria.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-08-25",
        href: "/case-studies/wisteria",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
        ],
    },
  ];
  